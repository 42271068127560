import * as React from "react";
import { Box, Typography, styled } from "@mui/material";
interface Props {
  testimonials: string[];
}

const Text = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: "black",
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.palette.custom.large,
}));

export const Testimonials: React.FC<Props> = ({ testimonials }) => {
  return (
    <Box
      sx={(theme) => ({
        padding: `${theme.palette.custom.xLarge} ${theme.palette.custom.xxLarge}`,
        display: "flex",
        flexDirection: "column",
        rowGap: theme.palette.custom.xLarge,
        [theme.breakpoints.down("md")]: {
          padding: `${theme.palette.custom.small} ${theme.palette.custom.medium}`,
        },
      })}
    >
      {testimonials!.map((item: any, key) => (
        <TextContainer>
          <Text variant="h5">{`${item.acf.quote}`}</Text>
          <Text variant="h5">{`- ${item.acf.author} -`}</Text>
        </TextContainer>
      ))}
    </Box>
  );
};
