import * as React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Home } from "./Home/Home";
import { Box, Modal } from "@mui/material";
import { Navbar } from "../components/Navbar.tsx/Navbar";
import { About } from "./About/About";
import { Contact } from "./Contact/Contact";
import { PhotographyNavigation } from "./Photography/PhotographyNavigation";
import { Video } from "./Video/Video";
import axios from "axios";
import { Brands } from "./Brands/Brands";
import { VideoNavigation } from "./Video/VideoNavigation";
import { Test } from "./Test/Test";

interface Props {}

export const PagesNavigation: React.FC<Props> = () => {
  const [image, setImage] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [mobileNav, setMobileNav] = React.useState(false);
  const [video, setVideo] = React.useState<string[]>([]);
  const [photo, setPhoto] = React.useState<string[]>([]);
  const [images, setImages] = React.useState<string[]>([]);
  const [banner, setBanner] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [title, setTitle] = React.useState("");

  const location = useLocation();
  const currentLocation = location.pathname;

  React.useEffect(() => {
    setImages([]);
    setBanner("");
    setTitle("");
  }, [currentLocation]);

  React.useEffect(() => {
    setLoading(true);
    let specificLocation = "";
    if (currentLocation.includes("brands")) {
      setTitle("");
      setLoading(false);
    } else if (currentLocation.includes("contact")) {
      setTitle("");
      setLoading(false);
    } else if (currentLocation.includes("photography")) {
      specificLocation = currentLocation.replace("/photography/", "");
      setTitle(specificLocation);
      axios
        .get(
          `https://headless.jonvickersmedia.com/wp-json/wp/v2/photo-${specificLocation.substring(
            0,
            13
          )}?per_page=40`
        )
        .then((res) => {
          setImages(res.data.reverse());
          setBanner(res.data[0].acf.image);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (currentLocation.includes("video")) {
      specificLocation = currentLocation.replace("/video/", "");
      setTitle(specificLocation);
      if (specificLocation === "full-productions-&-campaigns") {
        axios
          .get(
            "https://headless.jonvickersmedia.com/wp-json/wp/v2/video-full-productio"
          )
          .then((res) => {
            setBanner(res.data.reverse()[0].acf.banner);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        axios
          .get(
            "https://headless.jonvickersmedia.com/wp-json/wp/v2/video-eye-catching-v"
          )
          .then((res) => {
            setBanner(res.data.reverse()[0].acf.banner);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else if (currentLocation.includes("about")) {
      setTitle("");
      axios
        .get("https://headless.jonvickersmedia.com/wp-json/wp/v2/about")
        .then((res) => {
          setBanner(res.data[0].acf.banner);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setTitle("");
      axios
        .get(
          `https://headless.jonvickersmedia.com/wp-json/wp/v2/home?per_page=40`
        )
        .then((res) => {
          setImages(res.data.reverse());
          setBanner(res.data[0].acf.image);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [currentLocation]);

  React.useEffect(() => {
    axios
      .get(`https://headless.jonvickersmedia.com/wp-json/wp/v2/routes-video`)
      .then((res) => {
        let videoArray = [];
        setVideo(res.data);
        for (let i = 0; i < res.data.length; i++) {
          videoArray.push(res.data[i].acf.route);
        }
        setVideo(videoArray);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get(`https://headless.jonvickersmedia.com/wp-json/wp/v2/routes-photo`)
      .then((res) => {
        let photoArray = [];
        for (let i = 0; i < res.data.length; i++) {
          photoArray.push(res.data[i].acf.route);
        }
        setPhoto(photoArray.reverse());
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Box
      sx={(theme) => ({
        minHeight: "100vh",
        margin: "auto",
      })}
    >
      <Modal open={modal} onClose={() => setModal(false)}>
        <Box
          sx={(theme) => ({
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          })}
          onClick={() => setModal(false)}
        >
          <img
            onClick={() => setModal(false)}
            src={image}
            alt=""
            style={{ maxWidth: "95vw", height: "auto", maxHeight: "90vh" }}
          />
        </Box>
      </Modal>

      <Navbar
        title={title}
        loading={loading}
        banner={banner}
        photo={photo}
        video={video}
        mobileNav={mobileNav}
        setMobileNav={setMobileNav}
      />

      <Box
        sx={{
          maxWidth: "1600px",
          margin: "auto",
          zIndex: "-1",
          position: mobileNav ? "relative" : "static",
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Home
                images={images}
                loading={loading}
                image={image}
                modal={modal}
                setImage={setImage}
                setModal={setModal}
              />
            }
          />
          <Route
            path="/about"
            element={<About setBanner={setBanner} banner={banner} />}
          />
          <Route
            path="/contact"
            element={<Contact banner={banner} setBanner={setBanner} />}
          />
          <Route
            path="/photography/*"
            element={
              <PhotographyNavigation
                loading={loading}
                images={images}
                setModal={setModal}
                setImage={setImage}
              />
            }
          />
          <Route
            path="/brands"
            element={<Brands banner={banner} setBanner={setBanner} />}
          />
          <Route
            path="/video/*"
            element={
              <VideoNavigation
                banner={banner}
                loading={loading}
                setBanner={setBanner}
              />
            }
          />
          <Route path="/test" element={<Test />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </Box>
  );
};
