import * as React from "react";
import { Box } from "@mui/material";

interface Props {
  clients: string[];
}

export const Clients: React.FC<Props> = ({ clients }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "40px" }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          rowGap: "20px",
          columnGap: "20px",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          padding: `${theme.palette.custom.xLarge} ${theme.palette.custom.xxLarge}`,
          background: theme.palette.background.default,
        })}
      >
        {clients!.slice(1).map((item: any, key) => (
          <img
            key={key}
            style={{
              maxHeight: "100px",
              maxWidth: "200px",
            }}
            src={item.acf.image}
            alt=""
          ></img>
        ))}
      </Box>
    </Box>
  );
};
